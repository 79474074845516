/**
 * Msa Notification Service API
 * REST API for Msa Notification Service
 *
 * The version of the OpenAPI document: 2.0
 * Contact: dimilar-devops@bit.admin.ch 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Title {
    Mr = 'MR',
    Ms = 'MS',
    NotSpecified = 'NOT_SPECIFIED'
}

