/**
 * Msa Notification Service API
 * REST API for Msa Notification Service
 *
 * The version of the OpenAPI document: 2.0
 * Contact: dimilar-devops@bit.admin.ch 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationDto { 
    id?: string;
    creationDateTime?: string;
    modificationDateTime?: string;
    ahvNumber?: string;
    referenceId?: string;
    requestType?: string;
    notificationType?: NotificationDtoNotificationTypeEnum;
    subject?: { [key: string]: string; };
    body?: { [key: string]: string; };
    email?: string;
    emailSentAt?: string;
    mobile?: string;
    mobileSentAt?: string;
    readAt?: string;
}
export enum NotificationDtoNotificationTypeEnum {
    Request = 'REQUEST',
    Duty = 'DUTY',
    General = 'GENERAL',
    Support = 'SUPPORT'
};



